@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

[type="name"],
[type="email"] {
  background: none;
  border: none;
  padding: 0;
  border-bottom: solid 1px $colour-medium-gray;
  color: $colour-medium-gray;

  // .invalid & {
  //   :invalid {
  //     border-bottom-color: red;
  //   }
  // }
}
.field {
  margin-bottom: 1rem;
}
[type="submit"] {
  background: none;
  border: 1px solid $colour-medium-gray;
  border-radius: $border-radius;
  color: $colour-medium-gray;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.5em 1em;
}
