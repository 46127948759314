@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

@keyframes border-pulsate {
  0% {
    border-color: lighten($gradient-purple-start, 25%);
  }
  50% {
    border-color: lighten($gradient-purple-end, 25%);
  }
  100% {
    border-color: lighten($gradient-purple-start, 25%);
  }
}

.practice-areas {
  display: flex;

  li {
    flex: 1 1 0;
  }

  button {
    cursor: pointer;
    width: 100%;
    padding-bottom: 1em;
    border: none;
    border-width: 0;
    border-bottom: 5px solid $colour-medium-gray;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7em;

    @include breakpoint(lg) {
      font-size: 0.85em;
    }

    &.active {
      // border-bottom: 10px solid;
      border-image-slice: 1;
      border-image-source: $gradient-purple;
    }
    &.loading {
      animation: border-pulsate 1s infinite;
    }
  }
}
