#mhsu--feedback {
    .field {
        margin-bottom: 2rem;
    }

    padding: 2rem 0;

    textarea {
        width: 90%;
        min-width: 90%;
    }

    .errors {
        font-style: italic;
        font-size: 0.75em;
    }

    .feedback-success {
        text-align: center;
        img {
            width: 100%;
            max-width: 35em;
        }
    }
}