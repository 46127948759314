@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

.resource-module {
  display: flex;
  flex-direction: column;
  background-color: $colour-white;
  border-radius: $border-radius;
  box-shadow: 0px 25px 25px rgba(152, 152, 152, 0.14);
  overflow: hidden;
  height: 100%;
  transition: transform 150ms;

  &:hover,
  &:focus {
    transform: translateY(-0.4em);
  }
}
.info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
h3 {
  font-size: 0.9em;

  .skeleton & {
    background-color: $colour-light-gray;
    width: 100%;
    color: $colour-light-gray;
  }
}
h4 {
  margin-top: 0.4em;
  font-weight: normal;
  font-size: 0.8em;

  .skeleton & {
    background-color: $colour-light-gray;
    width: 40%;
    color: $colour-light-gray;
  }
}
.metadata {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin: 1em 0 0 0;
  margin-top: auto;
  padding-top: 1em;

  li {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1em;
    margin-right: 0.5em;

    .practice-area & {
      stroke: $gradient-purple-start;
    }
    .core-content & {
      stroke: $gradient-blue-start;
    }
  }
}
.time {
  text-transform: uppercase;
  // font-weight: bold;
  font-size: 0.8em;
}
.date {
  background: black;
  border-radius: $border-radius;
  color: $text-light;
  font-size: 0.75em;
  padding: 0.25em 0.5em;

  .skeleton & {
    color: rgba(255, 255, 255, 0);
  }

  .practice-area & {
    background: $gradient-purple;
  }
  .core-content & {
    background: $gradient-blue;
  }
  .additional-resource & {
    background-color: $colour-accent;
  }
}

.image {
  //   height: 5em;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }

  .skeleton & {
    background-color: $colour-light-gray;
    min-width: 100%;
    min-height: 1em;
  }
}
