@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";
@import "variables";

@import "fonts";

html {
  font-family: $font-family;
  background-color: $colour-offwhite;
  letter-spacing: 0.01em;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

[v-cloak] {
  opacity: 0;
}

// Override browser define global styles
@import "resets";

// Global styles for html tags
@import "ui";

@import "breakpoints";
@import "grid";

// Styles for Twig templates
@import "templates";

@import "helpers";

@import "bulma";
