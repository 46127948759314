@use "sass:math";

#services--areas {
    margin-right: math.div($outsideGutter, 2);
    a {
        display: block;
        background-color: $colour-light-gray;
        padding: 1.25em 1.5em;
        margin-bottom: 0.5rem;
        border-radius: $border-radius;

        &:hover {
            background-color: $colour-primary-light;
        }

        &.active {
            color: $text-light;
            background-color: $colour-primary-bright;
        }
    }
}
.services--area-dropdown {
    margin-bottom: 2rem;
}
.services--coc-dropdown {
    width: max-content;
    margin: 0 auto;
    text-align: left;

    @include breakpoint(sm) {
        margin: initial;
    }

    &.dropdown {
        display: block;
        margin-bottom: 1em;
    }

    .dropdown-trigger button {
        background: none;
        color: $text-light;
    }
    .navbar-link:not(.is-arrowless) {
        &:hover {
            background-color: unset;
        }

        &::after {
            border-color: $text-light;
        }
    }
}

#services--services {
    h2 {
        font-size: 1em;
        text-transform: uppercase;
        color: $colour-primary;
        margin-bottom: 2em;

        @include breakpoint(sm) {
            font-size: 1.25em;
        }
    }

    h3 {
        font-size: 1em;
    }

    h4 {
        font-size: 1.125em;
    }

    section {
        margin-bottom: 3rem;
    }

    .service {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    .info {
        svg {
            padding-top: 0.15em;
            height: 1em;
            width: 1.5em;
        }

        li {
            margin-bottom: 0.5em;
        }
    }

    .resources {
        li {
            margin-right: 1em;
            color: $colour-primary;
        }
    }

    // Style nested accordions
    .mhsu--acordion {
        font-size: 0.9em;
        @include breakpoint(sm) {
            font-size: 1em;
        }

        .mhsu--acordion {
            h3 {
                font-weight: normal;
            }
        }
    }
}
