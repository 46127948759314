@use "sass:math";

// Set variables used in sass-flexbox library

$grid-columns: 12; // Set number of columns in the grid

$xs-max: map-get($breakpoints, xs);
$sm-max: map-get($breakpoints, sm);
$md-max: map-get($breakpoints, md);
$lg-max: map-get($breakpoints, lg);
$xl-max: map-get($breakpoints, xl); 
  
$content-well-max-width: "none"; // Set the max-width of the content well

// Sass flexbox grid library
@import "grid/main";

// Container class
.container {
    margin: 0 auto;
    padding: 0 $gutter;

    @include breakpoint(sm) {
        max-width: 100%;
    }

    @include breakpoint(md) {
        max-width: map-get($breakpoints, md) - $outsideGutter;
    }

    @include breakpoint(lg) {
        max-width: map-get($breakpoints, lg) - $outsideGutter;
    }

    @include breakpoint(xl) {
        max-width: map-get($breakpoints, xl) - $outsideGutter;
    }

}

.no-outside-gutter {
    &:last-child {
        padding-right: 0;
    }
    &:first-child {
        padding-left: 0;
    }
}