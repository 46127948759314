#mhsu-sponsors {
    padding: 2rem 0;

    p {
        max-width: 40em;
    }

    .sponsors {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        margin-top: 2rem;

        li {
            padding: 1em 0;

            &:not(:last-child) {
                margin-right: 5em;
            }
        }
        img {
            max-height: 64px;
            max-width: 100%;
        }
    }
}
