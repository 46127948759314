#faq--accordion {
    margin-bottom: 5rem;

    h2 {
        font-size: 1em;
    }

    // .navbar-link::after {
    //     transition: all 200ms linear;
    // }
}
