$primary: $colour-primary;
$link: $colour-primary;
$radius: $border-radius;
$navbar-dropdown-radius: $border-radius;

$tablet: map-get($breakpoints, sm);
$desktop: map-get($breakpoints, md);
$widescreen: map-get($breakpoints, lg);
$fullhd: map-get($breakpoints, xl);

$navbar-breakpoint: $widescreen;

// Tabs
$message-background-color: none;
$tabs-boxed-link-active-background-color: $colour-primary-bright;
$tabs-toggle-link-active-background-color: $colour-primary-bright;
$tabs-toggle-link-hover-background-color: $colour-primary-light;
$tabs-toggle-link-border-width: 0px;
$tabs-link-padding: 1.5em;

// Message
$message-body-padding: 0 1em;
$message-header-padding: 1.5em 1em;
$message-header-background-color: none;
$message-radius: 0;
$message-header-radius: 0;
$message-header-color: $text-dark;

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/helpers/visibility";
@import "bulma/sass/helpers/spacing";
@import "bulma/sass/components/navbar";
@import "bulma/sass/components/message";
@import "bulma/sass/components/tabs";
@import "bulma/sass/components/dropdown";
@import "bulma/sass/elements/button";
@import "bulma/sass/form/shared";
@import "bulma/sass/form/select";
@import "bulma/sass/form/input-textarea";
@import "@creativebulma/bulma-collapsible";

.tabs a {
    background-color: $colour-primary-light;

    font-size: 0.7rem;
    padding: 1.25em;

    @media (min-width: map-get($breakpoints, sm)) {
        font-size: 1rem;
        padding: 1.5em;
    }
}

.message {
    .message-header {
        text-decoration: none !important;
    }
}

.select {
    background-color: $colour-primary-medium;
    color: white;
    border-radius: $border-radius;
    &::after {
        border-color: white !important;
    }
    select {
        color: white;
        background-color: unset;
        border: none;
    }
    option {
        color: black;
    }
}

// Hide expanded parts of menu on mobile layout
.navbar-item.has-dropdown {
    @media only screen and (max-width: $navbar-breakpoint) {
        .navbar-link::after {
            display: none;
        }
    }
}

.navbar-dropdown {
    @media only screen and (max-width: $navbar-breakpoint) {
        display: none;

        .navbar-link::after {
            opacity: 0;
        }
    }
}

a.dropdown-item {
    width: initial;
}

// Dropdown Arrows
.navbar-link:not(.is-arrowless)::after {
    margin-top: -0.6em;
}

.select:not(.is-multiple):not(.is-loading)::after {
    margin-top: -0.6em;
}