@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

.resources-section {
  margin-top: 2rem;

  h3 {
    margin-bottom: 1rem;
  }
}

h4 {
  margin-bottom: 0.25em;
}

.text {
  flex: 1;
  margin-left: 1em;
}

.resources-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.resource {
  background: $colour-light-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  &:hover,
  &:focus {
    background-color: darken($colour-light-gray, 7);
  }
}

.info {
  font-size: 0.75em;
  color: $colour-dark-gray;
}

.ext {
  text-transform: uppercase;
}
