$breakpoints: (
  xs: 322px, // Phones
  sm: 600px, // Slightly Larger Phones
  md: 800px, // Obnoxiously Large Phones
  lg: 1000px, // Tablets
  xl: 1200px, // Desktops
);

@mixin breakpoint($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    }
    @else {
        // Log a warning.
        @warn "Invalid breakpoint: #{$breakpoint}.";
    }
}
