#search--entry {
    background-color: $colour-light-gray;

    #search-input {
        width: 100%;
        padding: 1em;
        font-size: 1em;
        margin-bottom: 2em;
        -webkit-appearance: textfield;
        border: none;
        border-radius: $border-radius;
    }

    .filters {
        display: flex;

        select {
            width: 100%;
        }

        .select {
            width: 100%;

            &:not(:last-child) {
                margin-right: 1em;
            }
        }
    }

    .search-type {
        display: flex;

        button {
            background: none;
            border: none;
            padding: 1em;
            cursor: pointer;
            border-bottom: solid 5px $colour-light-gray;
            font-size: 0.75em;

            @include breakpoint(sm) {
                font-size: 0.85em;
            }

            &.active {
                border-bottom: solid 5px $colour-primary;
            }
        }
    }
}

#search--results {
    .search-spinner {
        width: 100%;
        text-align: center;
        font-size: 2em;
    }
    .message {
        margin-top: 1em;
    }
}
