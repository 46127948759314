#stream--streams {
    display: flex;
    color: $text-light;
    padding-bottom: 2em;

    @include breakpoint(md) {
        padding-bottom: 1em;
    }

    h3 {
        text-transform: uppercase;
        font-size: 1em;

        @include breakpoint(sm) {
            font-size: 1.17em;
        }
    }

    p {
        font-size: 0.5em;
        display: none;

        @include breakpoint(xs) {
            font-size: 0.75em;
        }

        @include breakpoint(sm) {
            display: block;
        }
    }

    a {
        flex: 1 1 0;
        padding: 1em 2em;
        // display: relative;
        position: relative;
        z-index: 1;

        &.active::before {
            margin: -1em 0;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            transition: margin 100ms;
        }

        &:hover:not(.active),
        &:focus:not(.active) {
            &::before {
                margin: -0.33em 0;
            }
        }

        &.core-content::before {
            background: $gradient-blue;
        }

        &.practice-areas::before {
            background: $gradient-purple;
        }
    }
}

.mhsu--stream {
    margin-bottom: 3rem;
}
