@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

// Styles for glider
@import "~glider-js/glider.css";

.glider-contain {
  .dots {
    margin-top: 1rem;
  }
}

.glider-dot {
  background: none;
  border: 1px solid $colour-white;

  &.active {
    background: $colour-white;
  }

  &:hover {
    background: rgba($colour-white, 0.5);
  }
}

.glider-next,
.glider-prev {
  color: $colour-white;

  &:hover,
  &:focus {
    color: rgba($colour-white, 0.5);
  }
}

@-moz-document url-prefix() {
  .glider-track {
    margin-bottom: 17px;
  }
  .glider-wrap {
    overflow: hidden;
  }
}
