footer {
    .main {
        background-color: $colour-dark-gray;
        color: $colour-white;
        padding: 2rem 0;
        font-size: 0.8em;

        .container {
            display: block;
            flex-wrap: wrap;
            justify-content: space-between;

            @include breakpoint(sm) {
                display: flex;
            }
        }

        h4 {
            margin: 0 0 2em 0;
            text-transform: uppercase;
        }
    }

    .top {
        width: 100%;
        font-size: 2em;
        text-align: center;

        @include breakpoint(sm) {
            text-align: left;
        }
    }

    .left {
        @include breakpoint(sm) {
            max-width: 18em;
        }
    }

    .right {
        display: flex;

        .about,
        .resources {
            margin-right: 2rem;
            display: none;

            @include breakpoint(sm) {
                display: block;
            }
        }
    }

    #newsletter {
        margin: 0 auto;
        
        &.flash-size {
            animation: flashSize 250ms linear;
        }
    }

    nav {
        a {
            display: block;
            margin-bottom: 0.4em;
        }
    }

    .bottom {
        width: 100%;
        margin-top: 3rem;
        padding-top: 1rem;
        text-align: center;

        @include breakpoint(sm) {
            border-top: 1px $colour-white solid;
            text-align: right;
        }
    }

    .sponsors {
        padding: 2rem;
        display: none;

        @include breakpoint(sm) {
            display: block;
            font-size: 0.6em;
        }

        @include breakpoint(lg) {
            font-size: 1em;
        }

        .container {
            display: flex;
        }

        h4 {
            align-self: center;
            font-size: 1em;
        }

        ul {
            display: flex;
            margin: 0;
        }

        li {
            margin: 0 1em;
        }

        img {
            height: 2em;
        }
    }
}

// Form size flash animation
@keyframes flashSize {
    from {
        transform: scale(1);
    }
    15% {
        transform: scale(1.1);
    }
    to {

    }
}
