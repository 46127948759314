header {
    position: sticky;
    top: 0;
    background-color: $colour-white;
    z-index: 5;

    .container {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include breakpoint(lg) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    svg, img {
        height: 2rem;
        padding-right: 2rem;
        max-height: initial !important;
    }

    .navbar-burger {
        margin: 0;
    }

    .navbar-item {
        text-transform: uppercase;
        font-size: 0.85em;
    }

    .navbar-dropdown .navbar-item {
        text-transform: initial;
    }

    nav {
        // display: flex;
        // justify-content: space-between;
        width: 100%;
        a {
            text-decoration: none;
            color: black;
            padding: 0.5em 0;

            &.active {
                border-bottom: 3px solid $colour-primary;
            }
        }

        .search-link svg {
            height: 1em;
            padding: 0;
        }

        .newsletter {
            // padding: 0.8em;
            // background-color: $colour-primary;
            // color: $text-light;
            // border-radius: $border-radius;
            margin-bottom: 0;
            font-size: 1em;
        }
    }
}
