@import "@/scss/variables.scss"; @import "@/scss/breakpoints.scss";

.featured-modules {
  margin: 0 2em;
}

.slide {
  padding: 0 1em;
  padding-top: 0.4em; //Leave room for hover float effect on learning modules
}
