#section--search {
    padding-top: 2rem;
    padding-bottom: 3rem;

    .inputs {
        display: flex;
        height: 2.5em;
    }

    input {
        width: 100%;
        padding: 1em;
        border-radius: $border-radius 0 0 $border-radius;

        &[type=search]{
            border: none;
            background-color: $colour-light-gray;
            padding-left: 2em;
        }
    }

    .button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin: 0;
        svg {
            height: 1em;
            stroke: white;
        }
    }
}