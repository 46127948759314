.mhsu--section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.mhsu--section-header {
    text-transform: uppercase;
    font-size: 1em;
}

.mhsu--acordion {
    border-top: 1px $colour-medium-gray solid;
    margin-bottom: 0 !important;

    &:last-child {
        border-bottom: 1px $colour-medium-gray solid;
    }

    .is-active {
        > .navbar-link::after {
            transform: rotate(-225deg);
        }
    }

    .message-body-content {
        margin: 1em 0;
    }
}

.mhsu--rating {
    width: 132px;
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 1.5em;
    text-align: center;
    position: relative;
    padding-bottom: 1em;

    > label {
        float: right;
        display: inline;
        padding: 0;
        margin: 0;
        position: relative;
        width: 1.1em;
        cursor: pointer;
        color: #000;
    }

    > label:hover,
    > label:hover ~ label,
    > input.radio-btn:checked ~ label {
        color: transparent;
    }

    > label:hover:before,
    > label:hover ~ label:before,
    > input.radio-btn:checked ~ label:before,
    > input.radio-btn:checked ~ label:before {
        content: "\2605";
        position: absolute;
        color: #ffd700;
    }
}

.b-pagination {
    margin: 0 auto;
    text-align: center;
    .page-item {
        display: inline-block;
        padding: 0.5em;

        &.active {
            button {
                color: $colour-accent;
            }
        }

        &.disabled {
            opacity: 0.5;
        }

        button {
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0);

            &:focus-visible {
                outline: none;
                border-bottom: 1px solid $colour-accent;
            }
        }
    }
    .next,
    .prev {
        .page-link {
            font-size: 1em;
            text-transform: uppercase;
        }
    }
    .page-link {
        border: none;
        background: none;
        padding: 0;
        font-size: 1.25rem;
        font-family: $font-family;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mhsu--spinner {
    border: 0.15em solid $colour-light-gray; /* Light grey */
    border-top: 0.15em solid $colour-primary-bright; /* Blue */
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    animation: spin 1s linear infinite;
    display: inline-block;
}
