@use "sass:math";

#landing--resources {
    padding-bottom: 3rem;
    // Streams
    nav {
        a {
            box-sizing: border-box;
            display: block;
            height: 100%;
            padding: 2rem;
            border-radius: $border-radius;
            color: $text-light;
            transition: transform 150ms;

            > * {
                transition: transform 150ms;
            }

            &:hover,
            &:focus {
                transform: scale(1.05);
                > * {
                    transform: scale(math.div(1,1.05));
                }
            }
        }

        p {
            font-size: 0.75em;
        }

        .core-content {
            background: $gradient-blue;
        }

        .practice-areas {
            background: $gradient-purple;
        }
    }

    h2 {
        margin-bottom: 2rem;
    }

    h3 {
        text-transform: uppercase;
    }

    .stream {
        &:not(:last-child) {
            margin-bottom: 2rem;
            @include breakpoint(sm) {
                margin-bottom: 0;
            }
        }
    }
}

.landing--featured {
    background-color: $colour-accent;
    padding-bottom: 2rem;

    &.orange-shirt-day {
        background-color: $colour-orange;
    }

    h2 {
        margin-bottom: 2rem;
        // padding-top: 1em;
        color: $text-light;
    }
}
