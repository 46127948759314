$colour-primary: #0078ae;
$colour-secondary: #009793;
$colour-tertiary: #009793;
$colour-accent: #4d88a3;
$colour-orange: #e3733d;

$colour-primary-bright: #4ba0ff;
$colour-primary-medium: #4da1c6;
$colour-primary-light: #e0eff5;

$colour-offwhite: #fefefe;
$colour-white: white;
$colour-dark-gray: #616161;
$colour-medium-gray: #c4c4c4;
$colour-light-gray: #f3f3f0;

$text-dark: #202020;
$text-light: $colour-white;

$gradient-purple-start: #614385;
$gradient-purple-end: #516395;

$gradient-blue-start: #06beb6;
$gradient-blue-end: #48b1bf;

$gradient-blue: linear-gradient(92.78deg, $gradient-blue-start 46.33%, $gradient-blue-end 89.5%);
$gradient-purple: linear-gradient(93.29deg, $gradient-purple-start -3.31%, $gradient-purple-end 103.81%);

$border-radius: 4px;

$font-family: "Lato", sans-serif;

$gutter: 20px; // Set gutter size

$outsideGutter: $gutter * 4;