#shared--hero {
    background-size: cover;
    padding: 3rem 0;
    color: $text-light;
    position: relative;
    z-index: 1;
    text-align: center;

    @include breakpoint(sm) {
        text-align: left;
        min-height: 10rem;
    }

    &.standard {
        padding: 2rem;

        @include breakpoint(sm) {
            padding: 3rem;
        }
    }

    &.landing, .landing {
        text-align: left;
    }

    // Dim Hero Image
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: black;
        opacity: 0.7;
        z-index: -1;
    }

    h1 {
        font-size: 1.5em;
        margin-bottom: 1rem;

        @include breakpoint(xs) {
            font-size: 2em;
        }

        @include breakpoint(sm) {
            font-size: 3em;
        }

        @include breakpoint(md) {
            font-size: 3em;
        }
    }

    h2 {
        font-size: 1em;

        @include breakpoint(sm) {
            font-size: 1.5em;
        }
    }

    h3 {
        margin-bottom: 2em;
        font-size: 1em;

        @include breakpoint(sm) {
            font-size: 1.2em;
        }
    }

    p {
        max-width: 30rem;
    }

    .description {
        display: none;

        @include breakpoint(sm) {
            display: inline;
        }
    }

    .acknowledgement {
        margin-top: 4rem;
        font-size: 0.6em;
    }
}
